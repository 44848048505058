#menuContent {

  //ion-content {
  //  --padding-top: 96px !important;
  //}
  //
  //&.large-padding-top ion-content {
  //  --padding-top: 116px !important;
  //}
}

@media screen and (max-width: 700px) {
  app-top-navbar {

    ion-header ion-toolbar {
      border-color: black;
      transition: transform 0.3s ease-in-out; /* Added transition */
      position: absolute;
      border-bottom: 1px solid var(--ion-border-color);
    }

    &.hidden-on-phone {
      ion-header ion-toolbar {
        transform: translateY(-100%);
      }
    }
  }
}

ion-label a {
  cursor: pointer;
}

.gb-transparent {
    --background: transaprent !important;
    --ion-item-background: transparent !important;
}

ion-content.md {
  --padding-top: 16px;

  &.no-padding {
    --padding-top: 0;
  }
}

.gb-primary-ion-detail {
    --detail-icon-color: var(--ion-color-primary);
}

.gb-border-bottom {
    border-bottom: 1px solid var(--gb-border-color);
}

.gb-border-top {
    border-top: 1px solid var(--gb-border-color);
}

.gb-no-border {
    border: none !important;
    --border-width: 0 !important;
}

.gb-no-padding-left {
    padding-left: 0;
}

.gb-large-icon {
    font-size: 24px;
}

.gb-note {
    font-size: 14px;
}

.gb-page-title-container {
  padding: var(--ion-padding) 0;

  &.no-padding {
    padding: 0;
  }

  &.only-bottom-padding {
    padding: 0 0 var(--ion-padding) 0;
  }
}

.gb-page-title {
  font-family: "RobotoSlab", sans-serif;
  font-weight: 600;
  color: #4F4F4F;
  font-size: 22px;
}

.thumbnail-img-skeleton,
.thumbnail-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    overflow: auto;
}

.gb-footer-button {
    margin: 10px;
}

.gb-bottom-popover {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);

  &::part(content) {
    bottom: 0 !important;
    padding: 20px;
    left: 0 !important;
    //transform: translate(0, 0) !important;
    width: 100%;
    overflow: initial;
    //border-top: 1px solid var(--ion-border-color);
    background-color: var(--ion-background-color);
    //-webkit-transition: all 20s ease-in-out;
    //-moz-transition: all 20s ease-out;
    //-ms-transition: all 20s ease-out;
    //-o-transition: all 20s ease-out;
    //transition: all 20s ease-out;
    top: unset !important;
    border-radius: 0;
  }

  &::part(arrow) {
    display: none;
  }
}

.gb-center-popover {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);

  &::part(content) {
    padding: 20px;
    width: 80%;
    //height: 70%;
    max-width: 600px;
    //overflow: initial;
    //overflow-y: auto;
    //background-color: var(--ion-background-color);
    //border-color: var(--ion-border-color);
    //border-radius: 10px;
    //--offset-x: 50%;
    //--offset-y: 50%;
    //top: 50% !important;  /* position the top  edge of the element at the middle of the parent */
    //left: 50% !important; /* position the left edge of the element at the middle of the parent */
    //transform: translate(-50%, -50%) !important; /* This is a shorthand of
    //                                     translateX(-50%) and translateY(-50%) */
  }

  &.fixed-height {
    &::part(content) {
      height: 70%;
    }
  }

  &::part(arrow) {
    display: none;
  }
}

.gb-button-dropdown-menu {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);
  border-radius: 10px;
}

.gb-header {
  color: var(--ion-text-color);
  font-family: 'Patua One', serif;
  font-size: 22px;
  letter-spacing: 0.47px;
  line-height: 27px;
}

.gb-section-title {
  font-family: "RobotoSlab", sans-serif;
  font-weight: 600;
  color: #4F4F4F;
  font-size: 18px;
  text-wrap: pretty !important;
}

.gb-small-section-title {
  font-family: "RobotoSlab", sans-serif;
  font-weight: 700;
  color: var(--ion-color-medium);
  font-size: 0.8em;
  padding-bottom: var(--half-ion-padding);

  &.with-half-padding {
    padding: var(--half-ion-padding);
  }
}

.strip-long-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.gb-xsmall-text-size {
  font-size: var(--xsmall-text-size) !important;
}

.gb-small-text-size {
  font-size: var(--small-text-size) !important;
}

.gb-medium-text-size {
  font-size: var(--medium-text-size) !important;
}

.gb-large-text-size {
  font-size: var(--large-text-size) !important;
}

.gb-xlarge-text-size {
  font-size: var(--xlarge-text-size) !important;
}

.gb-bottom-toolbar {
  border-color: var(--gb-border-color);
  --background: var(--ion-background-color);
  background-color: var(--ion-background-color);
}

@keyframes flashingAnimation {
  50% {
    opacity: 0;
  }
}

.gb-text-with-border {
  overflow: hidden;
  text-align: center;
}

.gb-text-with-border:before,
.gb-text-with-border:after {
  background-color: var(--ion-border-color);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 10%;
}

.gb-text-with-border:before {
  right: 0.5em;
  margin-left: -30%;
}

.gb-text-with-border:after {
  left: 0.5em;
  margin-right: -30%;
}

.gb-top-toolbar {
  --background: var(--ion-background-color);
  --border-width: 0 !important;

  &.collapsed {
    height: 0;
    --min-height: 0;
  }
}

.gb-top-toolbar-with-border {
  --background: var(--ion-background-color);

  &.collapsed {
    height: 0;
  }
}

.no-scrollbar {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.cupertino-pane-wrapper .pane {
  background: var(--ion-background-color) !important;
  z-index: 100;
}

.cupertino-pane-wrapper.fullscreen {
  .draggable {
    visibility: hidden;
  }

  .pane {
    border-radius: 0 !important;
    padding-top: 0 !important;
  }
}

.multi-line-labels-with-padding {
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  .first-line {
    font-size: 14px;
  }

  .second-line {
    font-size: 12px;
    color: var(--ion-color-medium);
  }
}

.multi-line-labels {
  display: flex;
  flex-direction: column;

  .first-line {
    font-size: 14px;
  }

  .second-line {
    font-size: 12px;
    color: var(--ion-color-medium);
  }

  .third-line {
    font-size: 11px;
  }
}

.small-searchbar .searchbar-input-container {
  input {
    font-size: 12px !important;
  }

  .searchbar-search-icon {
    transform: scale(0.6);
  }
}

.gb-floating-card {
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: #ccc;
    left: 20px;
    right: 20px;
    bottom: 0;
    top: 50%;
    z-index: -1;
    box-shadow: 0 0 40px lighten(#000, 60%);
    transition: box-shadow .2s ease-in-out;
  }

  &.level-3 {
    &:hover {
      &:before {
        box-shadow: 0 0 80px lighten(#000, 60%);
      }
    }
  }
}

.before-blue-header {
  background-color: var(--ion-color-primary);
  top: 0;
  height: 20px;
  width: 100%;
}

.blue-header {
  //background-size: calc(100% - 40px) 100%;
  position: relative;

  .header-img-container {
    display: flex;
    justify-content: flex-end;
    margin: 20px 20px 0 0;
    flex: 1;

    img {
      z-index: 1;
    }
  }

  .header-bg {
    background-color: var(--ion-color-primary);
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  .after-header-bg {
    background-color: var(--ion-background-color);
    width: 100%;
    left: 0;
    position: absolute;
    height: 27px;
  }

  .welcome-container {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    flex: 1;
    padding-top: 20px;

    &.hello-phrase {
      justify-content: center;
    }

    ion-label {
      padding: 5px;
      z-index: 1;
    }

    .page-title {
      font-size: 22px;
      font-family: 'RobotoSlab', sans-serif;
      margin-bottom: 10px;
      height: 30px;
    }

    .page-subtitle {
      font-size: 14px;
      z-index: 1;

      ion-icon {
        margin-right: 10px;
        color: yellow;
      }
    }
  }
}

.home-top-toolbar {
  display: flex;
  --background: var(--ion-color-primary);
  transition: all 300ms;
  --border-width: 0 !important;

  &.transparent {
    border: none !important;
    --border-width: 0 !important;
    --background: transparent;
  }

  &.white-background {
    --background: var(--ion-background-color);
    --border-width: 1px !important;

    ion-icon, ion-menu-button, ion-back-button {
      color: var(--ion-color-primary);
    }
  }

  ion-menu-button, ion-back-button {
    color: #fff;
  }

  ion-icon {
    color: #fff;
    font-size: 24px;
    margin: 5px;
  }
}

.input-with-border {
  border: 1px solid var(--ion-color-light);
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
}

.input-item-with-background {
  --background: rgba(var(--ion-color-medium-rgb), 0.2);
  margin: 0 var(--ion-margin);
  border-radius: 10px;
  font-size: 14px;
  --inner-padding-top: var(--half-ion-margin);
  --inner-padding-bottom: var(--half-ion-margin);
}

//.wash-type-icon {
//  font-size: 45px;
//  align-self: flex-end;
//  padding-top: 15px;
//
//  .inside-the-bubble-icon,
//  .the-waxed-bubble-icon,
//  .the-glossy-bubble-icon {
//    zoom: 1.3
//  }
//
//  .outside-the-bubble-icon,
//  .the-waxed-bubble-icon,
//  .the-platinum-bubble-icon {
//  }
//}

.gb-chips-row {
  scrollbar-width: none;
  display: flex;
  overflow: scroll;
  flex-direction: row;
}

.gb-chip-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid var(--ion-border-color);
  white-space: nowrap;
  font-size: 14px;
  align-items: center;
  cursor: pointer;

  &.selected {
    background-color: var(--ion-color-primary);
    color: white;
    border-color: var(--ion-color-primary);

    &:hover {
      background-color: var(--ion-color-primary);
    }
  }

  &:hover {
    --background: #e9e9e9;
    background: #e9e9e9;
  }

  &.column-direction {
    flex-direction: column;
  }

  &.disabled {
    color: var(--ion-border-color);
  }

  &.selected-border {
    border-color: var(--ion-color-primary);
  }
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.trim-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  &.three-lines {
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
  }
}

@media screen and (min-width: 700px) {
  .gb-page {
    margin-top: var(--ion-margin) !important;
    padding: 0 var(--ion-padding);
  }

  ion-header {
    margin: 0 auto;
    max-width: var(--max-page-width);
    width: 90%;

    &.global-desktop-header {
      background: var(--ion-background-color);
      border-bottom: 1px solid var(--ion-border-color);
      //box-shadow: rgba(56, 58, 62, 0.15) 0 2px 8px;
      width: 100%;
      max-width: 100%;
    }
  }

  //  padding-top: 100px;
  //
  //  &.desktop-global-header {
  //    padding-top: 0;
  //  }
  //}

  ion-modal {
    ion-header {
      width: 100%;
      max-width: 100%;
    }

    ion-content {
      --padding-top: 0;
    }
  }

  .multi-column-boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: var(--max-page-width);
    margin: 0 auto;

    &.left-container-bigger {
      .column-box:first-child {
        flex-grow: 2;
        max-width: 60%;
      }

      .column-box:last-child {
        flex-grow: 1;
        max-width: 40%;
      }
    }

    &.ion-margin-top {
      margin-top: var(--ion-margin) !important;
    }

    &.right-container-bigger {
      .column-box:first-child {
        flex-grow: 1;
        max-width: 40%;
      }

      .column-box:last-child {
        flex-grow: 2;
        max-width: 60%;
      }
    }

    &.reverse-direction {
      flex-direction: row-reverse;
    }

    .column-box {
      border-radius: var(--gb-border-radius);
      padding: var(--ion-padding);
      box-shadow: rgb(56 58 62 / 15%) 0 2px 8px;

      &:first-child {
        margin-right: calc(var(--ion-margin) * 2);
      }

      &.no-shadow {
        box-shadow: none;
      }

      &.no-padding {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 700px) {

  .gb-top-toolbar.mobile-with-border {
    border-bottom: 1px solid var(--ion-border);
    --border-width: 0 0 0.55px !important;
  }

  .multi-column-boxes {
    .column-box {
      &.no-shadow-on-small-screen {
        box-shadow: none;
      }

      &.no-padding-bottom-small-screen {
        padding-bottom: 0;
      }
    }
  }

  .desktop-tool-bar-buttons {
    display: none;
  }

  .desktop-tool-bar-buttons {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
  }

  .elevated-box {
    &.margin-on-small-screen {
      margin: 0 var(--ion-margin);
    }
  }

  .vertical-padding-on-small-screen {
    padding: var(--ion-padding) 0;
  }

  .gb-page {
    padding: var(--ion-padding);
  }
}

.gb-page {
  margin: 0 auto;
  max-width: var(--max-page-width);

  &.no-padding {
    padding: 0;
  }

  &.horizontal-padding {
    padding: 0 var(--ion-padding);
  }
}

.gb-multiple-sections {
  .gb-section {
    margin: var(--ion-margin) 0;
  }
}

.gb-section {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--ion-border-color);

  &.no-bottom-border {
    padding-bottom: 0;
    border: none;
  }

  .gb-section-content {
    font-size: 14px;
    padding-top: var(--ion-padding);

    a {
      cursor: pointer;
    }

    &.no-padding {
      padding-top: 0;
    }
  }

  &.center-items {
    align-items: center;
  }
}

.location-snapshot::part(image) {
  object-fit: cover;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.circular-segment-container {
  padding: 0 var(--ion-padding);

  &.centered {
    margin: var(--ion-margin) auto;
    max-width: 500px;
    width: 100%;
  }

  .circular-segment {
    padding: 2px;
    border-radius: 20px;

    .circular-segment-button {
      --border-radius: 20px;
      font-size: 14px;
      --padding-top: calc(var(--ion-padding) / 2);
      --padding-bottom: calc(var(--ion-padding) / 2);
    }
  }
}

.big-loading-spinner {
  align-self: center;
  transform: scale(3);
  margin: 10% auto;
  display: flex;

  &.no-vertical-align {
    margin-top: var(--ion-margin) !important;
  }
}

.alert-button.red-alert-button {
  color: var(--ion-color-danger) !important;
}

.elevated-box {
  border-radius: var(--gb-border-radius);
  box-shadow: rgb(56 58 62 / 15%) 0 2px 8px;
  border: 1px solid var(--ion-border-color);
  margin-bottom: var(--ion-margin);
  padding: var(--ion-padding);
}

.gb-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.space-between {
    justify-content: space-between;
  }
}

.gb-flex-column {
  display: flex;
  flex-direction: column;
}

.item-error-label {
  color: var(--ion-color-danger) !important;
  font-size: 12px !important;
  max-width: 100% !important;
  white-space: normal !important;
}

.rzypro-link {
  color: #146b7b;
}
